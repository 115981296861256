<template>
	<header-block title="Voucher" color="white" bgcolor="#FBF7F466" border="0"></header-block>
	<CustomerLoadWait :customer="availableVoucher" />
	<transition>
		<div v-if="availableVoucher">
			<div class="header-image" :style="'background-image: url(' + availableVoucher.cardUrl + ')'">
				<div class="header" >
				<v-row class="mt-1">
					<v-col class="v-col-9">
						<h1>{{ availableVoucher.merchant.name }}</h1>
						<p>{{ availableVoucher.description.substring(0, 200) }}...</p>
						<p class="percentage">{{ availableVoucher.discountPercentage }}% cashback</p>
						<v-chip v-if="availableVoucher.online" variant="outlined" background-color="blue">Online</v-chip> <v-chip v-if="availableVoucher.inStore" variant="outlined">In Store</v-chip>
					</v-col>
					<v-col class="v-col-3 logo-col">
						<img :src="availableVoucher.merchant.logoUrl">
					</v-col>
				</v-row>
				</div>
			</div>
			
			<div class="cart" v-if="!pay">
				<h2>Value you would like to purchase</h2>
				<v-text-field
					v-if="!availableVoucher.allowedAmounts"
					v-model="amount"
					type="number"
					prefix="£"
					d--ensity="compact"
					label="Enter amount"
					variant="solo"
					:flat="true"
					h--ide-details
					clearable
					single-line
					bg-color="white"
				></v-text-field>
				<v-select
					v-if="availableVoucher.allowedAmounts"
					v-model="amount"
					prefix="£"
					:items="availableVoucher.allowedAmounts.split(',').map(Number)"
					label="Select amount"
					variant="solo"
					:flat="true"
					clearable
					single-line
					bg-color="white"
				></v-select>
				<p v-if="availableVoucher.lowerAmount && availableVoucher.lowerAmount > 0" class="constraints small">
					Minimum {{formatter.format(availableVoucher.lowerAmount)}}. 
					Maximum {{ formatter.format(availableVoucher.upperAmount) }}. 
					{{ availableVoucher.minorUnit && availableVoucher.minorUnit > 0 ? 'Increments of ' + formatter.format(availableVoucher.minorUnit) : '' }}. 
				</p>
				<v-btn @click="validatePayment()" :flat="true" class="mt-7 mg-button">Checkout</v-btn>
				<v-alert class="mt-4" density="compact" type="info" variant="outlined" v-if="errorText" :text="errorText" />
			</div>

			<div class="cart" v-if="pay">
				<v-btn v-if="closable" :flat="true" density="compact" icon="mdi-close" class="cancel" @click="pay=false; paymentLoading=false; paymentResponse=null; paymentPending=false; voucherResponse=null" />
				<h2>Purchase amount: {{formatter.format(amount)}}</h2>
				<p class="constraints small">{{formatter.format(amount * (availableVoucher.discountPercentage / 100)) }} cashback *</p>
				<v-switch v-if="!paymentLoading && !paymentResponse && vouchers && vouchers.currentSavings > 0" v-model="useCredit" :label="'Use ' + formatter.format(vouchers.currentSavings) + ' savings'" />
				<v-btn v-if="!paymentLoading && !paymentResponse" @click="stripePay()" :flat="true" class="mt-7 mg-button">Pay by Card</v-btn>
				<v-btn v-if="paymentLoading && !paymentResponse" :flat="true" class="mt-7 mg-button"><v-progress-circular indeterminate /></v-btn>
				<StripeCheckout class="mt-2" v-if="paymentResponse" :secret="paymentResponse.paymentLink" :stripeKey="$route.meta.stripeKey" />
				<p class="centered constraints small mt-6"><v-icon style="margin-top:-4px;" icon="mdi-lock"></v-icon> Payments are protected by bank grade encryption.</p>
			</div>
			
			<div class="howto">
				<h2>How it works</h2>
				<ul>
					<li>Enter the value of the voucher you would like to purchase</li>
					<li>Click "Add to Cart" or "Buy now"</li>
					<li>Make payment</li>
					<li>Your voucher will be added to your account</li>
					<li>Some vouchers need to be "claimed" before they can be used.  Follow the on screen instructions</li>
				</ul>
				<p class="mt-3">* A small Stripe transaction processing fee is added to the purchase price. This allows us to provide you with the full cashback amount without any deductions later.</p>
			</div>

		</div>

	</transition>

</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import StripeCheckout from './elements/StripeCheckout.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, StripeCheckout},
	data() {
		return {
			useCredit: false,
			amount: null,
			errorText: null,
			pay: false,
			paymentLoading: false,
			paymentResponse: null,
			paymentPending: false,
			voucherResponse: null,
			closable: true
		}
	},
	methods: {

		onLoad: async function() {
			this._getCustomer();
			this._getCustomerVouchers();
			this._getAvailableVoucher(this.$route.params.id);
		},

		stripePay: function() {
			this.paymentLoading = true;
			this.closable = false;
			var meta = {
				type: "voucher", 
				voucherId: this.availableVoucher.id,
				slug: this.availableVoucher.merchant.slug, 
				amount: this.amount
			}
			var paymentRequest = {
				meta: JSON.stringify(meta),
				useVoucherCredit: this.useCredit,
				type:1,
				returnUrl: window.location.href.split("#")[0] + "#/PaymentComplete",
				amountInPence: this.amount * 100,
				productDescription: "MG " + this.availableVoucher.merchant.name + " Voucher",
				paymentReference: this.availableVoucher.merchant.name
			}
			this._createPayment(paymentRequest, (response) => {
				this.paymentResponse = response.data;
				this.paymentPending = true;
			});
		},

		validatePayment: function() {
			this.errorText = null;
			if (this.amount == null || this.amount == 0) {
				this.errorText = "Please enter a valid amount";
				return;
			}
			if (this.availableVoucher.lowerAmount && this.availableVoucher.lowerAmount > 0 && (this.amount < this.availableVoucher.lowerAmount || this.amount > this.availableVoucher.upperAmount)) {
				this.errorText = "Please enter an amount between " + this.formatter.format(this.availableVoucher.lowerAmount) + " and " + this.formatter.format(this.availableVoucher.upperAmount);
				return;
			}
			if (this.availableVoucher.minorUnit && this.availableVoucher.minorUnit > 0.01 && (this.amount % this.availableVoucher.minorUnit != 0)) {
				this.errorText = "Please enter an amount in multiples of " + this.formatter.format(this.availableVoucher.minorUnit);
				return;
			}
			this.pay = true;
		}

	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
	h1 {
		font-size: 24px;
	}
	.header-image {
		margin: -20px -30px 0px -30px;
		background-size: cover;
		margin-top: -138px;
		padding-top: 138px;
		color: white;
	}
	.header {
		margin-top: -138px;
		background-color: #281c3be7;
		padding: 30px;
		padding-top: 138px;
		color: white;
		padding-bottom: 200px;
	}
	.logo-col {
		text-align: right;
	}
	.logo-col img {
		width: 70px;
		border-radius: 40px;
	}
	.percentage {
		font-size: 16px;
		font-weight: bold;
	}
	p{
		font-size: 13px;
	}
	.cart {
		background-color: var(--background-color-alt);
		padding: 30px;
		margin-top: -150px;
		border-radius: 30px;
		padding-bottom: 40px;
		h2 {
			margin-bottom: 15px;
		}
		.constraints {
			margin-top: -10px;
			padding-top: 0px;
		}
		.small {
			font-size: 11px;
		}
		position: relative;
	}
	.howto {
		background-color: var(--background-color-alt);
		padding: 30px;
		margin-top: 20px;
		border-radius: 30px;
		padding-bottom: 40px;
		font-weight: normal;
		h2 {
			margin-bottom: 15px;
		}
		.constraints {
			margin-top: -10px;
			padding-top: 0px;
		}
		.small {
			font-size: 11px;
		}
		ul{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.cancel{
		position: absolute;
		right: 20px;
		top: 20px;
		
	}

</style>
