<template>
	<header-block v-if="customer" title="Completed moves"></header-block>
	<CustomerLoadWait :customer="customer" />
	<div v-if="customer">
		<!-- <HeaderBlock :title="'Hi, ' + customer.person.firstName" /> -->
		<v-row v-if="completedMoves" class="mb-5">
			<v-col>
				<!-- <InfoPanel :icon="completedMoves[0].move.thumbnail" title="Moves" valueTitle="Completed" :value="customer.facts['M_COMPLETED'] ?? '0'"/> -->
				<CountBlock title="Moves completed" :value="customer.facts['M_COMPLETED'] ?? '0'"></CountBlock>
			</v-col>		
		</v-row>
		
		<div v-if="completedMoves">
			<v-row v-for="m in completedMoves" :key="m.id">
				<v-col>
					<MovePanel :move="m.move" :customerMove="m" />
				</v-col>
			</v-row>
		</div>
		
	</div>
</template>

<script>
import Services from '../mixins/Services';
import CountBlock from './elements/CountBlock.vue';
import MovePanel from './elements/MovePanel.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CountBlock, MovePanel, CustomerLoadWait, HeaderBlock},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			
			this._getCustomer();
			this._getCompletedMoves();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
