<template>
	<header-block :title="timeOfDay + (customer ? (', ' + customer.person.firstName) : '')"></header-block>
	<CustomerLoadWait :customer="customer && bankInfo" />
	<transition>
		<v-row v-if="bankInfo">
			<v-col>
				<AnalysisWidget :analysis="spendingAnalysis" :formatter="formatter" />
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row v-if="bankInfo">
			<v-col>
				<AccountsWidget :accounts="transactionAccounts" :formatter="formatter"/>
			</v-col>
		</v-row>
	</transition>
	<transition>
		<div v-if="pendingMoves" class="moves-title-row">
			<v-row class="title-row">
				<v-col class="title-col">Moves</v-col>
				<v-col class="right" style="padding-top: 20px;"><a href="">See All</a></v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="moves-widget-col">
					<MovesWidget v-if="pendingMoves" :moves="pendingMoves" />
				</v-col>
			</v-row>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import AccountsWidget from './elements/AccountsWidget.vue';
import AnalysisWidget from './elements/AnalysisWidget.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MovesWidget from './elements/MovesWidget.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, AnalysisWidget, AccountsWidget, MovesWidget},
	data() {
		return {
			
		}
	},
	computed: {
		transactionAccounts() {
			var ret = [];
			if(this.bankInfo == null) {
				return ret;
			}
			this.bankInfo.accounts.forEach(a => {
				if (a.accountType.prettyName.toLowerCase() == "current") {
					ret.push(a);
				}
			});
			return ret;
		},
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getBankingInfo();
			this._getPendingMoves();
			this._getSpendingAnalysis();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title-col {
	font-size: 20px;
	padding-bottom: 0px;
}

.moves-title-row
{
	margin-top: 10px;
	.v-col {
		padding-bottom: 5px;
		a {
			text-decoration: underline;
		}
	}
}

.moves-widget-col {
	padding-top: 0px;
}

</style>
