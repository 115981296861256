import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import { createRouter, createWebHashHistory } from 'vue-router';
import axios from 'axios';

import App from './App.vue'
import Notifications from './components/NotificationsPage.vue'
import WhatsAppCoaching from './components/WhatsAppCoachingPage.vue'
import MoneyHubLink from './components/MoneyHubLinkPage.vue'
import MoneyHubCallback from './components/MoneyHubCallbackPage.vue'
import YouPage from './components/YouPage.vue'
import CompletedMovesPage from './components/CompletedMovesPage.vue'
import EnableWhatsApp from './components/EnableWhatsApp.vue';
import AiopPage from './components/AiopPage.vue';

// Vuetify
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import TestPage from './components/TestPage.vue';
import AccountsPage from './components/AccountsPage.vue';
import AccountPage from './components/AccountPage.vue';
import GuidesPage from './components/GuidesPage.vue';
import HomePage from './components/HomePage.vue';
import GuidePage from './components/GuidePage.vue';
import ArticlePage from './components/ArticlePage.vue';
import GuideSectionPage from './components/GuideSectionPage.vue';
import VouchersShopPage from './components/VouchersShopPage.vue';
import VoucherShopPage from './components/VoucherShopPage.vue';
import PaymentCompletePage from './components/PaymentCompletePage.vue';
import LoginPage from './components/LoginPage.vue';
import VouchersPage from './components/VouchersPage.vue';
import VoucherPage from './components/VoucherPage.vue';

const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
})

var stripeKey = "pk_live_51QG05vC4AKqm93HpI6MDqVHKiZjxjlgqsIDqooQ6rIUhLHDkpsFAjq9MIOUypzVy1sXwfc854ukr7B6UFfqPHLXv00ZWFRrUo4";
var api = "https://api.moneyguided.com";
var configUri = "dev";

const router = createRouter({
	history: createWebHashHistory(),
	scrollBehavior() {
		return { top: 0 };
	},
	routes: [
		{
			path: '/', component: HomePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'home',
				// backgroundColor: 'white'
				backgroundImage: require('@/assets/bg/bg.png')
			}
		},
		{
			path: '/notifications', component: Notifications,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/whatsappcoaching', component: WhatsAppCoaching,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/moneyhublink', component: MoneyHubLink,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/moneyhubcb/:code', component: MoneyHubCallback,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/you', component: YouPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'you'
			}
		},
		{
			path: '/guides', component: GuidesPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'guide'
			}
		},
		{
			path: '/guides/:id', component: GuidePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white'
			}
		},
		{
			path: '/guidesection/:id', component: GuideSectionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white'
			}
		},
		{
			path: '/article/:id', component: ArticlePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white'
			}
		},
		{
			path: '/completedmoves', component: CompletedMovesPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'you'
			}
		},
		{
			path: '/enablewhatsapp', component: EnableWhatsApp,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/Aiop', component: AiopPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money'
			}
		},
		{
			path: '/Login', component: LoginPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundImage: require('@/assets/bg/bg.png')
			}
		},
		{
			path: '/Accounts/:accountType', component: AccountsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money'
			}
		},
		{
			path: '/Account/:id', component: AccountPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money'
			}
		},
		{
			path: '/Vouchers', component: VouchersShopPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/MyVouchers', component: VouchersPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/MyVouchers/:id', component: VoucherPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Vouchers/:id', component: VoucherShopPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white',
				stripeKey: stripeKey
			}
		},
		{
			path: '/PaymentComplete', component: PaymentCompletePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Test', component: TestPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				backgroundColor: 'white'
			}
		}
	]
});

const app = createApp(App);
app.config.globalProperties.$http = axios;
app.use(VueCookies);
app.use(router);
app.use(vuetify);
app.mount("#app");
