export default {

	data() {
		return {
			api: null,
			configUri: null,
			customer: null,
			employer: null,
			bankInfo: null,
			bankAccount: null,
			insuranceInfo: null,
			mortgageInfo: null,
			investmentInfo: null,
			articles: null,
			article: null,
			disabledFeatures: [],
			enabledFeatures: [],
			spendingAnalysis: null,
			availableVouchers: null,
			availableVoucher: null,
			vouchers: null,
			voucher: null,
			categories: [],
			plans: null,
			plan: null,
			planSection: null,
			products: [],
			payment: null,
			benefits: [],
			config: null,
			token: null,
			completedMoves: null,
			pendingMoves: null,
			formatter: new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'GBP',
				trailingZeroDisplay: 'stripIfInteger',
			}),
			timeOfDay: new Date().getHours() < 12 ? 'Morning' : new Date().getHours() < 18 ? 'Afternoon' : 'Evening',
			today: new Date().toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short'
			}),
			yesterday: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short'
			}),
			menuShown: this.$route.meta.bottomMenu,
			bottomMenuHeight: 76,
			drawerBottom: this.$route.meta.bottomMenu ? 46 : -23,
		}
	},

	created: function() {
		this.api = this.$route.meta.api;
		this.configUri = this.$route.meta.configUri;
		if (this.$route.query.u) {
			this.$cookies.set('user', this.$route.query.u);
			this.$cookies.set('pass', this.$route.query.p);
			this._login(this.$route.query.u, this.$route.query.p);
		}
		else if (this.$cookies.get('token')) {
			this.token = this.$cookies.get('token');
			this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
		}
		else if (this.$cookies.get('user')) {
			this._login(this.$cookies.get('user'), this.$cookies.get('pass'));
		}
		else if (this.$route.query.t) {
			this.token = this.$route.query.t;
			this.$cookies.set('token', this.token);
			this.refreshToken = this.$route.query.r;
			this.$cookies.set('refreshToken', this.refreshToken);
			this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
		}
		else{
			this.$router.push({ path: '/Login' });
		}
	},

	methods: {

		_enableWhatsApp: function () {
			var request = {
				"title": "welcome_to_mg",
				"whatsApp": true,
				"whatsAppTemplate": true,
				"phoneNumber": this.customer.person.mobilePhone
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Notifications", request)
				.then(() => {
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._enableWhatsApp();
					});
				}
			});
		},

		_savePreferences: function (onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Preferences", this.customer.preferences)
				.then((result) => {
					this.customer = result.data;
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._savePreferences(onSuccess);
						});
					}
				});
		},

		_getAppConfig: function(onSuccess = null) {
			this.$http.get("https://s3.eu-west-2.amazonaws.com/config.moneyguided.com/" + this.configUri + "_config.json")
				.then((result) => {
					this.config = result.data;
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._getAppConfig(onSuccess);
						});
					}
				});
		},

		_getCustomer: function(onSuccess = null) {
			if (!this.customer) {
				this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000?getFacts=true")
				.then((result) => {
					this.customer = result.data;
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._getCustomer(onSuccess);
						});
					}
				});
			} else {
				if (onSuccess) {
					onSuccess();
				}
			}
		},

		_getEmployer: function(employerId, onSuccess = null) {
			if (!this.employer) {
				this.$http.get(this.api + "/Employers/" + employerId)
				.then((result) => {
					this.employer = result.data;
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._getEmployer(employerId, onSuccess);
						});
					}
				});
			} else {
				if (onSuccess) {
					onSuccess();
				}
			}
		},

		// _getFeatures: function(onSuccess = null) {
		// 	this._getEmployer(this.customer.employerID, () => {
		// 		this._parseFeatures();
		// 		if (onSuccess) {
		// 			onSuccess();
		// 		}
		// 	})
		// 	.catch((result) => {
		// 		if (result.response.status == 401) {
		// 			this._refreshLogin(() => {
		// 				this._getFeatures(onSuccess);
		// 			});
		// 		}
		// 	});
		// },

		// _parseFeatures: function() {
		// 	this.disabledFeatures = [];
		// 	this.enabledFeatures = [];
		// 	for (var x = 0; x < this.employer.features.length; x++) {
		// 		if (!this.employer.features[x].enabled) {
		// 			this.disabledFeatures.push(this.employer.features[x].feature.name);
		// 		} else {
		// 			this.enabledFeatures.push(this.employer.features[x].feature.name);
		// 		}
		// 	}
		// 	console.log(this.disabledFeatures);
		// 	console.log(this.enabledFeatures);
		// },

		_getProducts: function(employerId, onSuccess = null) {
			this.products = [];
			this.benefits = [];
			this.$http.get(this.api + "/Products/?employerId=" + employerId)
				.then((result) => {
					this.products = result.data;
					for (var x = 0; x < this.products.length; x++) {
						if (this.products[x].thumbnail.startsWith("Logos/")) {
							this.products[x].thumbnail = this.products[x].thumbnail.replace('Logos/', 'https://s3.eu-west-2.amazonaws.com/content.sysodev.link/Logos/')
						}
						this.products[x].img = this.products[x].thumbnail;
						if (this.products[x].tags == "employeeBenefit") {
							this.benefits.push(this.products[x]);
						}
					}
					console.log(this.products);
					console.log(this.benefits);
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._getProducts(employerId, onSuccess);
						});
					}
				});
		},

		_getCompletedMoves: function() {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves?filterByActioned=true")
				.then((result) => {
					this.completedMoves = result.data;
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCompletedMoves();
					});
				}
			});
		},

		_getPendingMoves: function() {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves")
				.then((result) => {
					this.pendingMoves = result.data;
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getPendingMoves();
					});
				}
			});
		},

		_getBankingInfo: function(onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/Info?lite=false")
				.then((result) => {
					this.bankInfo = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getBankingInfo(onSuccess);
					});
				}
			});
		},

		_getBankAccount: function(id, onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/" + id + "?lite=false&groupTransactions=true&limitTransactions=true")
				.then((result) => {
					this.bankAccount = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getBankAccount(id, onSuccess);
					});
				}
			});
		},

		_getSpendingAnalysis: function(onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Spending/Analysis")
				.then((result) => {
					this.spendingAnalysis = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getSpendingAnalysis(onSuccess);
					});
				}
			});
		},

		_getInsuranceInfo: function(onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Policies")
				.then((result) => {
					this.insuranceInfo = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getInsuranceInfo(onSuccess);
					});
				}
			});
		},

		_getMortgageInfo: function(onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/Mortgages")
				.then((result) => {
					this.mortgageInfo = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getMortgageInfo(onSuccess);
					});
				}
			});
		},

		_getInvestmentInfo: function(onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/InvestmentHubAccounts")
				.then((result) => {
					this.investmentInfo = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getInvestmentInfo(onSuccess);
					});
				}
			});
		},

		_getCmsArticles: function(onSuccess = null) {
			this.$http.get(this.api + "/CMS/Articles")
				.then((result) => {
					this.articles = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCmsArticles(onSuccess);
					});
				}
			});
		},

		_getCmsArticle: function(id, onSuccess = null) {
			this.$http.get(this.api + "/CMS/Articles/" + id)
				.then((result) => {
					this.article = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCmsArticle(id, onSuccess);
					});
				}
			});
		},

		_getPlans: function(onSuccess = null) {
			this.$http.get(this.api + "/Plans")
				.then((result) => {
					this.plans = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getPlans(onSuccess);
					});
				}
			});
		},

		_getPlan: function(id, onSuccess = null) {
			this.$http.get(this.api + "/Plans/" + id)
				.then((result) => {
					this.plan = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getPlan(id, onSuccess);
					});
				}
			});
		},

		_getPlanSection: function(id, onSuccess = null) {
			this.$http.get(this.api + "/Plans/Section/" +id)
				.then((result) => {
					this.planSection = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getPlanSection(id, onSuccess);
					});
				}
			});
		},

		_getAvailableVouchers: function(onSuccess = null) {
			this.$http.get(this.api + "/Discounts")
				.then((result) => {
					this.availableVouchers = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getAvailableVouchers(onSuccess);
					});
				}
			});
		},

		_getAvailableVoucher: function(id, onSuccess = null) {
			this.$http.get(this.api + "/Discounts/" + id)
				.then((result) => {
					this.availableVoucher = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getAvailableVoucher(id, onSuccess);
					});
				}
			});
		},

		_getCategories: function(onSuccess = null) {
			this.$http.get(this.api + "/Discounts/Categories")
				.then((result) => {
					this.categories = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCategories(onSuccess);
					});
				}
			});
		},

		_getCustomerVouchers: function(onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers")
				.then((result) => {
					this.vouchers = result.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCustomerVouchers(onSuccess);
					});
				}
			});
		},

		_getCustomerVoucher: function(id, onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers/" + id)
				.then((result) => {
					this.voucher = result.data;
					if (onSuccess) {
						console.log(result.data)
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCustomerVoucher(id, onSuccess);
					});
				}
			});
		},

		_getPayment: function(paymentId, onSuccess = null) {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Payments?providerPaymentId=" + paymentId)
				.then((response) => {
					this.payment = response.data;
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getPayment(paymentId, onSuccess);
					});
				}
			});
		},

		_createPayment: function(paymentRequest, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Payments", paymentRequest)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._createPayment(paymentRequest, onSuccess);
					});
				}
			});
		},

		_orderVoucher: function(slug, amount, paymentId, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers?brandSlug=" + slug + "&amount=" + amount + "&paymentId=" + paymentId, null) 
				.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._orderVoucher(slug, amount, paymentId, onSuccess);
					});
				}
			});
		},
		_deleteVoucherTransaction: function(voucherId, transactionId, onSuccess = null) {
			console.log("_deleteVoucherTransaction");
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers/" + voucherId + "/Transactions/" + transactionId)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteVoucherTransaction(voucherId, transactionId, onSuccess);
					});
				}
			});
		},
		_addVoucherTransaction: function(id, amount, onSuccess = null) {
			const requestBody = {
				amount: amount
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers/" + id + "/Transactions", requestBody)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addVoucherTransaction(id, amount, onSuccess);
					});
				}
			});
		},

		_login: function(email, password, onSuccess = null) {
			this.$http.post(this.api + "/Sessions", {
				"userName": email,
				"password": password
			})
				.then((result) => {
					this.token = result.data.session.token;
					this.$cookies.set('token', result.data.session.token, { expires: '30m' });
					this.$cookies.set('refreshToken', result.data.session.refreshToken);
					this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
					if (onSuccess) {
						onSuccess();
					}
				}
			);
		},

		_refreshLogin: function(onSuccess = null) {
			if (!this.$cookies.get('user') || !this.$cookies.get('pass')) {
				window.location.href = "/MG_POP.html";
			}
			var email = this.$cookies.get('user');
			var password = this.$cookies.get('pass');
			this._login(email, password, onSuccess);
		},

	}
  }