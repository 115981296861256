<template>
  <div id="body" :style="'background-color: ' + ($route.meta.backgroundColor ? $route.meta.backgroundColor : '#FBF7F4' + ';' + ($route.meta.backgroundImage ? 'background: url(' + $route.meta.backgroundImage + ')' : ''))">
    <div id="app">
        <router-view :key="$route.query"></router-view>
        <MenuBar v-if="$route.meta.bottomMenu" />
    </div>
  </div>
</template>

<script>
import MenuBar from './components/elements/MenuBar.vue';
export default {
  name: 'App',
  components: {
    MenuBar
  }
}
</script>

<style>

:root {
  --background-color: white;
  --text-color: #281C3B;
  --light-text-color: white;
  --border-color: gainsboro;
  --background-color-alt: #FBF7F4;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/Gotham-Book.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Gotham-Heavy';
  src: url('assets/Gotham-Medium.ttf');
  font-display: block;
}

* { font-family: 'Gotham'; }

/* Actual HTML body element outside of app control */
body{
  margin:0px;
  background-color:var(--alt-color);
  color: var(--text-color);
  font-size: 12px;
  min-height: calc(100vh);
  font-family: gotham-heavy;
}

/* 'Our' HTML body element inside app control */
#body {
  background-color: var(--alt-color);
  padding-left: 30px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 10px;
  margin-bottom: 78px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

#app
{
  background-color:transparent;
  min-height: calc(100vh - 60px);
}

h1 {
	font-size: 32px;
  margin-bottom: 8px;
  font-family: gotham;
}

.bold {
  font-family: gotham-heavy;
}

strong {
  font-family: gotham-heavy;
}

h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-family: gotham;
}

p {
  margin-bottom: 9px;
  font-family: gotham;
  f--ont-weight: lighter;
  font-size: 15px;
}

.middle-page {
  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)
}

.middle-page-topthird {
  position: absolute; top: 30%; left: 50%; transform: translate(-50%, -50%)
}

.v-label {
  font-size: small !important;
  color: var(--text-color) !important;
  opacity: 100 !important;
  font-size: 12px !important;
}

.pageHeaderIcon {
  width: 40px;
  margin-bottom: 20px;
  /* margin-right:auto;
  margin-left:auto;
  display: block; */
}

.pageHeaderIconBigger {
  width: 80px;
  margin-bottom: 20px;
  /* margin-right:auto;
  margin-left:auto;
  display: block; */
}

.mg-button {
    display: block !important;;
    border-radius: 30px !important;
    height: 50px !important;
    background-color: var(--text-color) !important;
    color: var(--light-text-color) !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-top: 10px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 80% !important;
    margin-top: 30px !important;
    font-family: gotham-heavy;
}

.centered {
  text-align: center;
}

.blur {
	opacity: 0.7;
	filter: blur(1.2px);
}

a {
  text-decoration: none;
  color: unset;
}

.topTabButton
{
  border: 1px solid var(--border-color) !important;
  border-radius: 4px !important;
  font-size: 11px !important;
  padding: 10px !important;
  box-shadow: none !important;
  m--argin:0px !important;
  background-color: transparent !important;
  color: var(--text-color) !important;
  width: 100% !important;
}

.active
{
  background-color: var(--text-color) !important;
  color: var(--light-text-color) !important;
}

.v-navigation-drawer__scrim {
  background-color: var(--background-color) !important;
  opacity: 0 !important;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.right {
  text-align: right;
}

.allowYScrollContainer
{
	overflow: scroll;
	scrollbar-width: none;
}

.allowYScrollContent {
	display: inline-block;
	white-space: nowrap;
}

.nowrap {
  overflow: hidden;
	text-wrap: nowrap;
	max-width: 100%;
	display: block;
	text-overflow: ellipsis
}

.no-top-padding {
  padding-top: 0px !important;
}

.tp-7 {
  padding-top: 7px !important;
}

.tp-4 {
  padding-top: 4px !important;
}

.tp-2 {
  padding-top: 2px !important;
}

.v-skeleton-loader {
  background-color: transparent !important;
}

</style>
