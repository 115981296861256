<template>

	<div v-if="!customer && isSpinner" class="middle-page-topthird">
		<v-progress-circular indeterminate></v-progress-circular>
	</div>

	<div v-if="!customer && !isSpinner" class="mt-5">
		
		<div v-if="cardBlocks">
			<v-row v-if="withTopCard">
				<v-col>
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="card"
					/>
				</v-col>
			</v-row>
			<v-row >
				<v-col>
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="paragraph"
					/>
				</v-col>
			</v-row>
			<v-row >
				<v-col>
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="card"
					/>
				</v-col>
				<v-col>
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="card"
					/>
				</v-col>
			</v-row>
			<v-row >
				<v-col>
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="card"
					/>
				</v-col>
				<v-col>
					<v-skeleton-loader
						class="mx-auto"
						width="100%"
						type="card"
					/>
				</v-col>
			</v-row>
		</div>
		
		<v-skeleton-loader v-if="!cardBlocks"
			class="mx-auto"
			width="100%"
			type="card, article, article, article, article"
		/>
			
	</div>

</template>

<script>
export default {
	name: "CustomerLoadWait",
	props: { isSpinner: { type: Boolean, default: true }, customer: Object, cardBlocks: { type: Boolean, default: false }, withTopCard: { type: Boolean, default: true } },
	//props: ['customer', 'isSpinner', 'cardBlocks', 'withTopCard'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
			
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>


</style>